import { useQueryLoading } from "@vue/apollo-composable";
import _ from "lodash";
import { defineStore } from "pinia";
import {
    useGetDatabaseTypesQuery,
    type Report_Condition_Type,
    type Vehicle_Body_Type,
    type Vehicle_Transmission_Type,
    type Vehicle_Status_Type,
    type Vehicle_Condition_Type,
    type Appointment_Type,
    type Payment_Method_Type,
    type Payment_Category_Type,
    type Booking_Status_Type,
    type Auction_Status_Type,
    type Session_Status_Type,
    type Session_Repeat_Type,
} from "~/graphql/types";
import type { ReportCategory } from "~/types/inspection";

/**
 * Pinia composable for database and extra type enums
 * @returns Objects containing types
 */
export const useDatabaseTypes = defineStore("types", () => {
    const { result, loading: isLoading } = useGetDatabaseTypesQuery({
        fetchPolicy: 'cache-and-network',
        pollInterval: 30000
    });

    watch(result, () => {
        if (result.value) {
            result.value.vehicleTransmissionTypes.forEach((transmission) => {
                vehicle.value.transmissionTypes.push(transmission);
            });
            result.value.vehicleBodyTypes.forEach((type) => {
                vehicle.value.bodyTypes.push(type);
            });
            result.value.vehicleConditionTypes.forEach((type) => {
                vehicle.value.conditionTypes.push(type);
            });
            result.value.auctionStatusTypes.forEach((type) => {
                vehicle.value.auctionStatusTypes.push(type);
            });
            result.value.vehicleStatusTypes.forEach((type) => {
                vehicle.value.statusTypes.push(type);
            });
            result.value.reportConditionTypes.forEach((type) => {
                inspection.value?.reportConditions.push(type);
            });
            result.value.appointmentTypes.forEach((type) => {
                inspection.value.appointmentTypes.push(type);
            });
            inspection.value.reportCategories = result.value.reportCategories.map(
                ({ id, key, comment }) => {
                    return {
                        id,
                        key,
                        comment: comment || _.capitalize(key),
                    };
                }
            );
            result.value.bookingStatusTypes.forEach((type) => {
                appointment.value.bookingStatusTypes.push(type);
            });
            result.value.paymentMethodTypes.forEach((type) => {
                finance.value.paymentMethods.push(type);
            });
            result.value.paymentCategoryTypes.forEach((type) => {
                finance.value.paymentCategories.push(type);
            });
            result.value.sessionStatusTypes.forEach((type) => {
                session.value.sessionStatusTypes.push(type);
            });
            result.value.sessionRepeatTypes.forEach((type) => {
                session.value.sessionRepeatTypes.push(type);
            });
        }
    })

    const vehicle = ref<{
        auctionStatusTypes: Auction_Status_Type[];
        transmissionTypes: Vehicle_Transmission_Type[];
        bodyTypes: Vehicle_Body_Type[];
        engineTypes: string[];
        statusTypes: Vehicle_Status_Type[];
        conditionTypes: Vehicle_Condition_Type[];
        fuelTypes: string[];
        assemblyTypes: string[];
        registrationTypes: string[];
        drivetrainTypes: string[];
        colors: string[];
    }>({
        auctionStatusTypes: [],
        transmissionTypes: [],
        bodyTypes: [],
        get engineTypes() {
            const engines: string[] = [];

            for (let i = 0.1; i <= 15; i += 0.1) {
                engines.push(i.toFixed(1));
            }

            engines.push('135.0', '194.0', '300.0', '385.0')

            return engines;
        },
        statusTypes: [],
        conditionTypes: [],
        fuelTypes: ["Petrol", "EV", "Diesel", "Hybrid", "Hybrid Petrol"],
        assemblyTypes: ["Locally Built", "Parallel Import"],
        registrationTypes: ["Individual", "Company"],
        drivetrainTypes: [
            "2WD (2 Wheel Drive)",
            "4WD (4 Wheel Drive)",
            "AWD (All Wheel Drive)",
        ],
        colors: [
            "Red",
            "Orange",
            "Yellow",
            "Green",
            "Cyan",
            "Blue",
            "Magenta",
            "Purple",
            "White",
            "Black",
            "Grey",
            "Silver",
            "Pink",
            "Maroon",
            "Brown",
            "Beige",
            "Tan",
            "Peach",
            "Lime",
            "Olive",
            "Turquoise",
            "Teal",
            "Navy Blue",
            "Indigo",
            "Violet",
            "Gold"
        ],
    });

    const inspection = ref<{
        reportConditions: Report_Condition_Type[];
        reportCategories: ReportCategory[];
        appointmentTypes: Appointment_Type[];
    }>({
        reportConditions: [],
        appointmentTypes: [],
        reportCategories: [],
    });

    const appointment = ref<{
        bookingStatusTypes: Booking_Status_Type[];
    }>({
        bookingStatusTypes: [],
    });

    const finance = ref<{
        paymentMethods: Payment_Method_Type[];
        paymentCategories: Payment_Category_Type[];
    }>({
        paymentMethods: [],
        paymentCategories: [],
    });

    const session = ref<{
        sessionStatusTypes: Session_Status_Type[];
        sessionRepeatTypes: Session_Repeat_Type[];
    }>({
        sessionStatusTypes: [],
        sessionRepeatTypes: [],
    });

    return { vehicle, inspection, appointment, finance, session, isLoading };
});
